import en from '@/locales/en.json'
import fr from '@/locales/fr.json'
import ch from '@/locales/ch.json'
import de from '@/locales/de.json'
import dk from '@/locales/dk.json'
import nl from '@/locales/nl.json'
import no from '@/locales/no.json'

export default defineI18nConfig(() => ({
    legacy: false,
    locale: 'en',
    messages: {
        en,
        fr,
        ch,
        de,
        dk,
        nl,
        no
    }
}))